<template>
  <div class="pa-sm-0 text-center d-flex flex-column gap-1 gap-lg-2">
    <div>
      <h1 class="justify-center mx-auto px-sm-0">
        {{ $t('product-finder.basic-info.title') }}
      </h1>
      <div class="my-4">
        {{ $t('product-finder.basic-info.sub-title') }}
      </div>
    </div>

    <div v-if="!completedFields.name">
      <h4 class="text-start pb-2">
        {{ $t('product-finder.basic-info.company-name-title') }}
      </h4>
      <v-text-field
        :value="name"
        class="ma-0 pa-0 name-input"
        hide-details
        outlined
        @click="onNameClick()"
        @input="$emit('name', $event)"
      />
    </div>

    <div v-if="!completedFields.country">
      <h4 class="text-start pb-2">
        {{ $t('product-finder.basic-info.country-box-title') }}
      </h4>
      <v-autocomplete
        :value="country"
        :items="availableCountries"
        clearable
        item-text="text"
        item-value="value"
        outlined
        hide-details
        :placeholder="$t('product-finder.basic-info.country-placeholder')"
        class="pointer"
        return-object
        @input="$emit('country', $event)"
        @click="onCountryClick()"
        @blur="onCountryBlur()"
        @click:clear="$emit('country', null)"
      >
        <template v-slot:selection="data">
          <span>{{ data.item.text }}</span>
        </template>
        <template v-slot:item="data">
          <span class="ml-2">{{ data.item.text }}</span>
        </template>
      </v-autocomplete>
    </div>

    <div v-if="!completedFields.industry">
      <h4 class="text-start pb-2">
        {{ $t('product-finder.additional-info.industry-box-title') }}
      </h4>
      <v-text-field
        :value="industry"
        hide-details
        outlined
        @click="onIndustryClick()"
        @input="$emit('industry', $event)"
      />
    </div>

    <div v-if="!completedFields.businessDescription">
      <h4 class="pb-2 text-start">
        {{ $t('product-finder.additional-info.description-box-title') }}
      </h4>
      <v-textarea
        ref="businessDescription"
        :value="businessDescription"
        :placeholder="$t('product-finder.enter-description-placeholder')"
        rows="5"
        class="my-2"
        outlined
        counter="5000"
        :rules="[rules.lengthWithEmptySpace({max: 5000})]"
        @input="$emit('businessDescription', $event)"
        @click="onBusinessDescriptionClick()"
      />
    </div>

    <div class="d-flex gap-1">
      <Button @click="$emit('back')">
        {{ $t('buttons.product-finder.back') }}
      </Button>
      <Button
        primary
        @click="$emit('next')"
      >
        {{ $t('buttons.product-finder.finish') }}
      </Button>
    </div>
  </div>
</template>

<script>
import countries from '@/mixins/countries'
import Button from './Button.vue'
import { lengthWithEmptySpace } from '@/lib/validation'

export default {
  components: { Button },
  mixins: [countries],
  props: {
    name: {
      type: String,
      default: ''
    },
    country: {
      type: Object,
      default: () => ({})
    },
    completedFields: {
      type: Object,
      default: () => ({})
    },
    industry: {
      type: String,
      default: ''
    },
    businessDescription: {
      type: String,
      default: ''
    }
  },
  watch: {
    country (val) {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Country selected', val.value)
    }
  },
  created () {
    this.rules = { lengthWithEmptySpace }
  },
  methods: {
    onCountryClick () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Country Selection opened')
    },
    onNameClick () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Name input clicked')
    },
    onIndustryClick () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Industry input clicked')
    },
    onBusinessDescriptionClick () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Business Description clicked')
    },
    onCountryBlur () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Country Selection closed')
    }
  }
}

</script>
